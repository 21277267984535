import {
  addDays,
  identifierRegex,
  isRelativeDate,
  LookupItem,
  maxDate,
  minDate,
  parseAndValidateReportExpression,
  parseRelativeDate,
  parseUserDate,
  userDateFormat,
  weekdayFormat
} from '@cumu/shared';
import { loc_invalidGeneral } from '@cumu/strings';
import { target } from '@github/catalyst';
import { getLocale } from 'static/src/scripts/locale';
import { HTMLValueElement } from '../forms/utilities';
import {
  addCustomValidator,
  getLabel,
  setValidationMessage
} from '../forms/validation';
import { controller } from './controller';
import { parseReportDefinition } from './report-expression-input';
import { dispatchChange } from './util';

const locale = getLocale();

@controller('date-input')
class DateInputElement extends HTMLElement {
  @target declare input: HTMLInputElement;
  @target declare pickerInput: HTMLInputElement;

  get relative() {
    return this.hasAttribute('relative');
  }

  get parametric() {
    return this.hasAttribute('parametric');
  }

  showPicker() {
    if (this.input.value === '') {
      this.pickerInput.valueAsDate = null;
    } else if (this.relative && isRelativeDate(this.input.value)) {
      this.pickerInput.valueAsDate = new Date(
        parseRelativeDate(Date.now(), this.input.value) ?? Date.now()
      );
    } else if (this.parametric && identifierRegex.test(this.input.value)) {
      this.pickerInput.valueAsDate = null;
    } else {
      this.pickerInput.valueAsDate = parseUserDate(this.input.value) ?? null;
    }
    this.pickerInput.showPicker();
  }

  suggestionSelected() {
    return Array.from<HTMLOptionElement>(this.input.list!.children as any)
      .map(o => o.value)
      .includes(this.input.value);
  }

  handlePickerInputChange(_event: Event) {
    let value = '';
    if (this.pickerInput.value) {
      // simplify valueAsDate / UTC handling.
      const [year, month, day] = this.pickerInput.value.split('-');
      value = `${+month}/${+day}/${year}`;
    }
    if (this.input.value !== value) {
      this.input.value = value;
      dispatchChange(this.input);
    }
  }

  handleInputChange(event: Event) {
    if (event.isTrusted && this.input.value) {
      const date = parseUserDate(this.input.value);
      if (date) {
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        this.input.value = `${month}/${day}/${year}`;
      } else if (
        this.parametric &&
        identifierRegex.test(this.input.value.trim().toUpperCase())
      ) {
        this.input.value = this.input.value.trim().toUpperCase();
      } else if (this.relative && isRelativeDate(this.input.value)) {
        // do nothing
      } else {
        setTimeout(() => {
          setValidationMessage(
            this.input,
            `${getLabel(this.input)} is invalid.`
          );
          this.closest('.form-group')?.classList.add('errored');
        });
      }
    }
  }

  updateSuggestions(/*event: Event*/) {
    let suggestions = getNumberSuggestions(this.input, this.relative);
    if (!suggestions.length) {
      suggestions = getWordSuggestions(
        this.input,
        this.relative,
        this.parametric
      );
    }
    let option = this.input.list!.firstElementChild as HTMLOptionElement;
    for (const suggestion of suggestions) {
      option ??= document.createElement('option');
      option.value = suggestion.id.toString();
      option.textContent = suggestion.title;
      if (!option.parentElement) {
        this.input.list!.append(option);
      }
      option = option.nextElementSibling as HTMLOptionElement;
    }
    while (option) {
      const next = option.nextElementSibling as HTMLOptionElement;
      option.remove();
      option = next;
    }

    // if (
    //   event.type === 'input' &&
    //   event.isTrusted &&
    //   this.input.value &&
    //   (parseUserDate(this.input.value) ||
    //     (this.parametric &&
    //       identifierRegex.test(this.input.value.trim().toUpperCase())) ||
    //     (this.parametric && isRelativeDate(this.input.value)))
    // ) {
    //   dispatchChange(this.input);
    // }
  }
}

function getWordSuggestions(
  input: HTMLInputElement,
  relative: boolean,
  parametric: boolean
): LookupItem[] {
  if (/[0-9/]/.test(input.value)) {
    return [];
  }
  const today = new Date();
  return [
    ...(parametric
      ? parseReportDefinition(input.form!).definition.parameters.map(x => ({
          id: x.name,
          title: x.name
        }))
      : []),
    {
      title: `Today${relative ? '' : ` (${weekdayFormat.format(today)})`}`,
      id: relative ? 'Today' : userDateFormat.format(today)
    },
    {
      title: `Yesterday${
        relative ? '' : ` (${weekdayFormat.format(addDays(today, -1))})`
      }`,
      id: relative ? 'Yesterday' : userDateFormat.format(addDays(today, -1))
    },
    {
      title: `Tomorrow${
        relative ? '' : ` (${weekdayFormat.format(addDays(today, 1))})`
      }`,
      id: relative ? 'Tomorrow' : userDateFormat.format(addDays(today, 1))
    }
  ];
}

function getNumberSuggestions(
  input: HTMLInputElement,
  relative: boolean
): LookupItem[] {
  const [, rawN] = /^\s*@?\s*(\d+)\s*$/.exec(input.value) || [];
  const n = parseInt(rawN);
  const today = new Date();
  const initialDate = relative ? null : parseUserDate(input.defaultValue);
  if (n > 0 && n <= 365) {
    const unit = n === 1 ? 'day' : 'days';
    return [
      {
        title: `${n} ${unit} ago${
          relative ? '' : ` (${weekdayFormat.format(addDays(today, 0 - n))})`
        }`,
        id: relative
          ? n === 1
            ? 'Yesterday'
            : `${n} ${unit} ago`
          : userDateFormat.format(addDays(today, 0 - n))
      },
      {
        title: `${n} ${unit} from now${
          relative ? '' : ` (${weekdayFormat.format(addDays(today, n))})`
        }`,
        id: relative
          ? n === 1
            ? 'Tomorrow'
            : `${n} ${unit} from now`
          : userDateFormat.format(addDays(today, n))
      },
      ...(initialDate
        ? [
            {
              title: `${n} ${unit} from ${userDateFormat.format(
                initialDate
              )} (${weekdayFormat.format(addDays(initialDate, n))})`,
              id: userDateFormat.format(addDays(initialDate, n))
            }
          ]
        : [])
    ];
  }
  return [];
}

declare global {
  interface Window {
    DateInputElement: typeof DateInputElement;
  }
  interface HTMLElementTagNameMap {
    'date-input': DateInputElement;
  }
}

addCustomValidator((input: HTMLValueElement, label: string) => {
  if (
    input instanceof HTMLInputElement &&
    input.type === 'text' &&
    input.value.length &&
    input.parentElement instanceof DateInputElement
  ) {
    const value = input.value;
    if (input.parentElement.relative && isRelativeDate(value)) {
      return null;
    }
    if (input.parentElement.parametric && identifierRegex.test(value)) {
      const {
        definition: { columns, parameters },
        properties
      } = parseReportDefinition(input.form!);
      const parameter = parameters.find(p => p.name === value);
      if (!parameter) {
        return `Invalid parameter reference. Unknown parameter "${value}".`;
      }
      if (['literal-date', 'select-date'].includes(parameter.type)) {
        return null;
      }
      if (parameter.type === 'expression') {
        const result = parseAndValidateReportExpression({
          name: parameter.name,
          expression: parameter.expression,
          getDefinitionInfo: () => ({ columns, parameters, properties })
        });
        if (result.valid && ['date', 'datetime'].includes(result.type)) {
          return null;
        }
      }
      return `Invalid parameter reference. Parameter "${value}" is not of type date.`;
    }
    const date = Date.parse(value);
    if (isFinite(date) && date >= minDate && date <= maxDate) {
      return null;
    }
    return loc_invalidGeneral[locale](label);
  }
  return null;
});
