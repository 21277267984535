import type { CoreExpression, Identifier, Literal } from 'jsep';
import jsep from 'jsep';
import { PropertyType } from '../model/entity-type';

export type ExpressionReturnType =
  | 'boolean'
  | 'date'
  | 'datetime'
  | 'int'
  | 'decimal'
  | 'string';

export const equivalentTypes: Record<
  ExpressionReturnType,
  ExpressionReturnType[]
> = {
  boolean: ['boolean'],
  date: ['datetime', 'date'],
  datetime: ['datetime', 'date'],
  int: ['decimal', 'int'],
  decimal: ['decimal', 'int'],
  string: ['string']
};

export const equivalentPropertyTypes: Record<
  ExpressionReturnType,
  PropertyType[]
> = {
  boolean: ['bit'],
  date: ['date'],
  datetime: ['date'],
  int: ['int', 'currency', 'decimal'],
  decimal: ['decimal', 'currency'],
  string: ['varchar100']
};

export function getLiteralType({
  value
}: Literal): ExpressionReturnType | null {
  if (typeof value === 'boolean') {
    return 'boolean';
  }
  if (typeof value === 'number') {
    return Number.isInteger(value) ? 'int' : 'decimal';
  }
  if (typeof value === 'string') {
    return 'string';
  }
  return null;
}

export function tryParseExpression(
  expression: string
): { valid: true; node: CoreExpression } | { valid: false; reason: string } {
  try {
    return { valid: true, node: jsep(expression) as CoreExpression };
  } catch (e) {
    if (
      e instanceof Error &&
      'description' in e &&
      typeof e.description === 'string' &&
      'index' in e &&
      typeof e.index === 'number'
    ) {
      return { valid: false, reason: e.description };
    }
    return { valid: false, reason: `Invalid expression "${expression}"` };
  }
}

function parenthesize(
  node: CoreExpression,
  ancestors: CoreExpression[],
  opts: {
    printIdentifier: (node: Identifier, ancestors: CoreExpression[]) => string;
    printLiteral: (node: Literal) => string;
    wrapDenominator: (printed: string) => string;
    printCalleeName?: (node: Identifier) => string;
    printCallExpression?: (calleeName: string, ...args: string[]) => string;
  }
) {
  const precedence: Record<string, number> = {
    '*': 12,
    '/': 12,
    '%': 12,
    '+': 11,
    '-': 11,
    '>': 9,
    '<': 9,
    '>=': 9,
    '<=': 9,
    '==': 8,
    '!=': 8,
    '&&': 4,
    '||': 3
  };
  const printed = printExpression(node, ancestors, opts);
  if (
    node.type === 'BinaryExpression' &&
    ancestors[0]?.type === 'BinaryExpression' &&
    precedence[node.operator] < precedence[ancestors[0].operator]
  ) {
    return `(${printed})`;
  }
  return printed;
}

export function standardPrintCallExpression(
  calleeName: string,
  ...args: string[]
) {
  return `${calleeName}(${args.join(', ')})`;
}

export function printExpression(
  node: CoreExpression,
  ancestors: CoreExpression[] = [],
  {
    printIdentifier = node => node.name.toUpperCase(),
    printLiteral = node =>
      typeof node.value === 'string'
        ? `'${node.value.replaceAll(`'`, `\\'`)}'`
        : node.value!.toString(),
    wrapDenominator = printed => printed,
    printCalleeName = node => node.name.toUpperCase(),
    printCallExpression = standardPrintCallExpression
  }: {
    printIdentifier?: (node: Identifier, ancestors: CoreExpression[]) => string;
    printLiteral?: (node: Literal) => string;
    wrapDenominator?: (printed: string) => string;
    printCalleeName?: (node: Identifier) => string;
    printCallExpression?: (calleeName: string, ...args: string[]) => string;
  } = {}
): string {
  switch (node.type) {
    case 'BinaryExpression': {
      const { left, right, operator } = node;
      const leftResult = parenthesize(
        left as CoreExpression,
        [node, ...ancestors],
        {
          printIdentifier,
          printLiteral,
          wrapDenominator,
          printCalleeName,
          printCallExpression
        }
      );
      const rightResult = parenthesize(
        right as CoreExpression,
        [node, ...ancestors],
        {
          printIdentifier,
          printLiteral,
          wrapDenominator,
          printCalleeName,
          printCallExpression
        }
      );
      if (operator === '/') {
        return `${leftResult} / ${wrapDenominator(rightResult)}`;
      }
      return `${leftResult} ${operator} ${rightResult}`;
    }
    case 'CallExpression': {
      const callee = node.callee as CoreExpression;
      if (callee.type !== 'Identifier') {
        throw new Error(`Expected Identifier, got ${callee.type}.`);
      }
      return printCallExpression(
        printCalleeName(callee),
        ...node.arguments.map(arg =>
          printExpression(arg as CoreExpression, [node, ...ancestors], {
            printIdentifier,
            printLiteral,
            wrapDenominator,
            printCalleeName,
            printCallExpression
          })
        )
      );
    }
    case 'Identifier': {
      return printIdentifier(node, ancestors);
    }
    case 'UnaryExpression': {
      const { operator, argument } = node;
      return `${operator}${printExpression(
        argument as CoreExpression,
        [node, ...ancestors],
        {
          printIdentifier,
          printLiteral,
          wrapDenominator,
          printCalleeName,
          printCallExpression
        }
      )}`;
    }
    case 'Literal': {
      return printLiteral(node);
    }
    default:
      throw new Error(`Unexpected node type "${node.type}".`);
  }
}
