export function dispatchChange(element: Element) {
  const event = new Event('change', { bubbles: true });
  element.dispatchEvent(event);
  return event;
}

export function focusSomething(element: Element | null): boolean {
  if (!element) {
    return false;
  }
  const selector =
    ':is(select,input,textarea,button,a,summary):not([hidden],[type="hidden"],[tabindex="-1"],[data-action*="click:editable-list#removeListItem"])';
  const target =
    element instanceof HTMLElement && element.matches(selector)
      ? element
      : element.querySelector<HTMLElement>(selector);
  if (target) {
    if (target instanceof HTMLInputElement) {
      target.select();
    } else if (target instanceof HTMLTextAreaElement) {
      target.setSelectionRange(target.value.length, target.value.length);
    }
    target.focus();
    return true;
  }
  return false;
}

export function animate(element: Element, classname: string) {
  if (element.classList.contains(classname)) {
    element.classList.remove(classname);
    setTimeout(animate, 0, element, classname);
    return;
  }
  element.classList.add(classname);
  const remove = () => element.classList.remove(classname);
  element.addEventListener('animationend', remove);
  element.addEventListener('animationcancel', remove);
}
