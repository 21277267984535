import {
  activitySelectMenuButtonContent,
  html,
  render,
  userUTCDateFormat
} from '@cumu/shared';
import { loc_loadingEllipses } from '@cumu/strings';
import { target } from '@github/catalyst';
import type RemoteInputElement from '@github/remote-input-element';
import { getLocale } from '../locale';
import { controller } from './controller';
import { dispatchChange } from './util';

const locale = getLocale();

const activitySelectChangeEvent = 'activity-select-change';

@controller('activity-select')
export default class ActivitySelectElement extends HTMLElement {
  @target declare preview: HTMLElement;
  @target declare input: HTMLInputElement;

  get remoteInput() {
    return this.querySelector<RemoteInputElement>('remote-input')!;
  }

  get parent_activity_definition_id() {
    return this.getAttribute('parent_activity_definition_id')!;
  }

  get activity_definition_id() {
    return this.getAttribute('activity_definition_id')!;
  }

  get organization_id() {
    return this.getAttribute('organization_id')!;
  }

  get activity_id() {
    return this.input.value || null;
  }

  get start_date() {
    return this.input.getAttribute('activity_start_date') || null;
  }

  get end_date() {
    return this.input.getAttribute('activity_end_date') || null;
  }

  get name() {
    return this.getAttribute('name')!;
  }

  get required() {
    return this.hasAttribute('required');
  }

  get label() {
    return this.getAttribute('label')!;
  }

  get period() {
    return this.hasAttribute('period');
  }

  get menuButton() {
    return this.querySelector<HTMLInputElement>('summary [data-menu-button]')!;
  }

  connectedCallback() {
    addEventListener(activitySelectChangeEvent, this);
    this.addEventListener('focus', this, { capture: true });
  }

  disconnectedCallback() {
    removeEventListener(activitySelectChangeEvent, this);
    this.removeEventListener('focus', this, { capture: true });
  }

  handleEvent(event: Event) {
    switch (event.type) {
      case activitySelectChangeEvent: {
        if (
          event.target === this ||
          !(event.target instanceof ActivitySelectElement)
        ) {
          return;
        }

        if (
          this.parent_activity_definition_id !==
          event.target.activity_definition_id
        ) {
          return;
        }

        // update remote-input.src
        const remoteInput = this.remoteInput;
        const url = new URL(remoteInput.src, location.origin);
        url.searchParams.delete('parent_activity_id');
        const activity_id = event.target.activity_id;
        if (activity_id) {
          url.searchParams.set('parent_activity_id', activity_id);
        }
        remoteInput.src = url.href;

        // clear selected value
        this.clear();
        return;
      }
      case 'focus': {
        const remoteInput = this.remoteInput;
        const url = new URL(remoteInput.src, location.origin);
        const form = this.closest('form');
        const startDateInput = form?.elements.namedItem('start_date:date');
        if (
          startDateInput instanceof HTMLInputElement &&
          isFinite(Date.parse(startDateInput.value))
        ) {
          url.searchParams.set(
            'start_date',
            new Date(startDateInput.value).toISOString().substr(0, 10)
          );
        }
        remoteInput.src = url.href;
        return;
      }
      default:
        throw new Error(`Unhandled event: ${event.type}`);
    }
  }

  detailsMenuSelected() {
    dispatchChange(this.input);
    this.dispatchEvent(
      new CustomEvent(activitySelectChangeEvent, { bubbles: true })
    );
    const activity_id = this.activity_id;
    this.preview?.remove();
    if (activity_id) {
      this.insertAdjacentHTML(
        'beforeend',
        render(
          html`<include-fragment
            src="/api/organizations/${this.organization_id}/activities/${this
              .activity_id}/summary-card?target=activity-select.preview"
            accept="text/fragment+html"
            class="d-flex flex-justify-center py-4 color-bg-inset border rounded"
            data-target="activity-select.preview"
          >
            <div class="spinner-border f1 color-fg-muted" role="status">
              <span class="sr-only">${loc_loadingEllipses[locale]}</span>
            </div>
          </include-fragment>`
        )
      );
    }
    if (this.period) {
      const start_date = this.start_date;
      const end_date = this.end_date;
      const form = this.closest('form');
      const startDateInput = form?.elements.namedItem('start_date:date');
      const endDateInput = form?.elements.namedItem('end_date:date')!;
      const dueDateInput = form?.elements.namedItem('due_date:date')!;
      if (
        form &&
        startDateInput instanceof HTMLInputElement &&
        endDateInput instanceof HTMLInputElement &&
        dueDateInput instanceof HTMLInputElement
      ) {
        startDateInput.value = start_date
          ? userUTCDateFormat.format(new Date(start_date))
          : '';
        endDateInput.value = end_date
          ? userUTCDateFormat.format(new Date(end_date))
          : '';
        dueDateInput.value = endDateInput.value;
        dispatchChange(startDateInput);
        dispatchChange(endDateInput);
        dispatchChange(dueDateInput);
      }
    }
  }

  inputFocused() {
    this.querySelector('summary')?.click();
  }

  clear() {
    // clear value
    if (this.activity_id) {
      this.menuButton.innerHTML = render(
        activitySelectMenuButtonContent(
          locale,
          this.name,
          this.required,
          this.label
        )
      );
      dispatchChange(this.input);
      this.dispatchEvent(
        new CustomEvent(activitySelectChangeEvent, { bubbles: true })
      );
    }

    // clear preview
    this.preview?.remove();

    // clear menu filter and results
    const filterInput = this.remoteInput.input!;
    filterInput.value = '';
    dispatchChange(filterInput);
    this.querySelector('SelectMenu-list')!.innerHTML = '';
  }
}

declare global {
  interface Window {
    ActivitySelectElement: typeof ActivitySelectElement;
  }
  interface HTMLElementTagNameMap {
    'activity-select': ActivitySelectElement;
  }
}
