import '@github/auto-complete-element/dist/auto-complete-element-define';
import Autocomplete from '@github/auto-complete-element/dist/autocomplete';
import '@github/clipboard-copy-element';
import '@github/details-menu-element';
import DetailsMenuElement from '@github/details-menu-element';
import '@github/file-attachment-element';
import '@github/filter-input-element';
import '@github/image-crop-element';
import '@github/include-fragment-element';
import '@github/markdown-toolbar-element';
import '@github/relative-time-element';
import '@github/remote-input-element';
import '@github/tab-container-element';
import '@github/text-expander-element';
import './attachments';
import './components/index';
import './forms/behavior-element';
import './hotkeys';
import './session';

// patch the autocomplete to automatically fetch results
const standardFetchResults = Autocomplete.prototype.fetchResults;
Autocomplete.prototype.fetchResults = function () {
  if (
    this.container.hasAttribute('empty-fetch') &&
    this.input.value.trim() === ''
  ) {
    this.input.value = '*';
    standardFetchResults.call(this);
    this.input.value = '';
  } else {
    standardFetchResults.call(this);
  }
};

addEventListener(
  'click',
  async event => {
    const target = event.target;
    if (!(target instanceof Element)) {
      return;
    }
    // todo... switch all these to "click-behavior" attributes

    // js-flash-close class
    {
      target.closest('.js-flash-close') && target.closest('.flash')?.remove();
    }

    // data-toggle-for attribute
    {
      const toggle = target.closest('[data-toggle-for]');
      if (toggle) {
        const id = toggle.getAttribute('data-toggle-for');
        if (id) {
          const toggleTarget = document.getElementById(id);
          if (toggleTarget) {
            toggleTarget.toggleAttribute(
              'open',
              !toggleTarget.hasAttribute('open')
            );
          }
        }
      }
    }

    // data-requires-submit
    {
      const button = target.closest(
        '[data-requires-submit]:is(button, a, label, summary)'
      ) as HTMLButtonElement | HTMLAnchorElement | HTMLLabelElement;
      const requiresSubmit =
        button &&
        (button.closest('modal-content')?.querySelector('form-behavior') ??
          document.querySelector('form-behavior'));
      if (requiresSubmit?.canSubmit) {
        event.preventDefault();
        event.stopPropagation();
        button.classList.add('btn-busy');
        await requiresSubmit.submit();
        button.classList.remove('btn-busy');
        if (!requiresSubmit.canSubmit) {
          target
            .closest<
              HTMLButtonElement | HTMLAnchorElement
            >('button, a, label, summary')
            ?.click();
        }
      }
    }

    // cookie dismiss
    {
      const button = target.closest('button[cookie-dismiss]');
      if (button) {
        button.closest('section')?.remove();
        const name = button.getAttribute('cookie-dismiss');
        if (name) {
          const maxAge =
            location.hostname === 'cumulus.care' ? 60 * 60 * 24 * 5 : 60 * 15; // 5 days or 15 minutes
          document.cookie = `${name}=1;path=1;${location.protocol === 'https:' ? 'secure;' : ''};samesite=lax;max-age=${maxAge}`;
        }
      }
    }

    // print button
    {
      const button = target.closest('[click-behavior="print"]');
      if (button) {
        print();
      }
    }
  },
  true
);

// addEventListener(
//   'mouseover',
//   ({ target }) => {
//     const details =
//       target instanceof HTMLElement &&
//       target.closest<HTMLDetailsElement>(
//         'details[data-hover-open]:not([open])'
//       );
//     if (details) {
//       console.log(details);
//       details.open = true;
//       details.lastElementChild?.addEventListener(
//         'mouseleave',
//         () => setTimeout(() => (details.open = false)),
//         {
//           once: true,
//           passive: true
//         }
//       );
//     }
//   }
//   // true
//   //{ capture: true, passive: true }
// );

addEventListener('clipboard-copy', ({ target }) => {
  if (!(target instanceof HTMLElement)) {
    return;
  }
  target.classList.add('btn-copied');
  setTimeout(() => target.classList.remove('btn-copied'), 1000);
});

const organizationHeader = document.getElementById(
  'sticky-organization-header'
);
if (organizationHeader) {
  const small = matchMedia('screen and (min-width: 544px)');
  const handler = () => {
    const scrolled =
      document.documentElement.scrollTop > 0 &&
      small.matches &&
      organizationHeader.getBoundingClientRect().top === 0;
    organizationHeader.classList.toggle('border-bottom', scrolled);
    organizationHeader.classList.toggle('color-shadow-medium', scrolled);
  };
  addEventListener('scroll', handler, { passive: true });
  small.addEventListener('change', handler, { passive: true });
  handler();
}

function getHighchartsThemeClass() {
  const colorMode = document.documentElement.getAttribute('data-color-mode');
  const theme =
    document.documentElement.getAttribute('data-light-theme') ?? 'light';
  return colorMode === 'auto'
    ? 'highcharts-auto'
    : theme.startsWith('dark')
      ? 'highcharts-dark'
      : 'highcharts-light';
}

document
  .getElementById('theme-select')
  ?.addEventListener('details-menu-selected', event => {
    const theme = event.detail.relatedTarget.getAttribute('theme') ?? 'auto';
    document.documentElement.setAttribute(
      'data-color-mode',
      theme === 'auto' ? 'auto' : 'light'
    );
    document.documentElement.setAttribute(
      'data-light-theme',
      theme === 'auto' ? 'light' : theme
    );

    const sidebar = document.querySelector('side-bar');
    const sidebarThemeMap: Record<string, string> = {
      light: 'dark',
      light_high_contrast: 'dark_high_contrast',
      dark: 'dark',
      dark_dimmed: 'dark_dimmed',
      dark_high_contrast: 'dark_high_contrast',
      dark_colorblind: 'dark_colorblind',
      light_colorblind: 'dark_colorblind',
      dark_tritanopia: 'dark_tritanopia',
      light_tritanopia: 'dark_tritanopia',
      auto: 'dark'
    };
    sidebar?.setAttribute(
      'data-color-mode',
      theme === 'auto' ? 'auto' : 'light'
    );
    sidebar?.setAttribute('data-light-theme', sidebarThemeMap[theme]);

    document.cookie =
      'theme=' +
      theme +
      ';path=/;' +
      (location.protocol === 'https:' ? 'secure;' : '') +
      'samesite=lax;max-age=31536000';

    const highchartsThemeClass = getHighchartsThemeClass();
    document.querySelectorAll('.highcharts-container').forEach(container => {
      container.classList.remove(
        'highcharts-auto',
        'highcharts-light',
        'highcharts-dark'
      );
      container.classList.add(highchartsThemeClass);
    });
  });

// if the selected details menu item has an input whose name attribute
// is missing, apply the current input's name
addEventListener(
  'details-menu-select',
  event => {
    let contents: DocumentFragment | Element | null =
      event.detail.relatedTarget.querySelector('[data-menu-button-contents]');
    if (contents instanceof HTMLTemplateElement) {
      contents = contents.content;
    }
    const input =
      contents?.querySelector<HTMLInputElement>('input:not([name])');
    const current =
      event.target instanceof DetailsMenuElement &&
      event.target
        .closest('details')
        ?.firstElementChild?.querySelector('input');
    if (input && current) {
      input.name = current.name;
    }
  },
  true
);

addEventListener('keydown', event => {
  if (
    event.key === ' ' &&
    event.target instanceof HTMLAnchorElement &&
    event.target.getAttribute('role') === 'button'
  ) {
    event.preventDefault();
    event.target.click();
  }
});
