import { avatar } from '../avatar';
import { html } from '../template';

export function assigneePickerUnassignedContent() {
  return html`
    <div
      class="d-flex text-left flex-items-center"
      data-value="${JSON.stringify({
        organization_id: null,
        user_id: null
      })}"
    >
      ${avatar(null, 32, 'mr-2')}
      <div class="d-flex flex-column" style="min-width:0">
        <span class="text-bold Truncate"
          ><span class="Truncate-text">None</span></span
        >
        <span class="f6 color-fg-muted text-normal Truncate"
          ><span class="Truncate-text">Not assigned</span></span
        >
      </div>
    </div>
  `;
}
