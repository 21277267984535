import { SharedResource } from './resource';

// https://regex101.com/r/CQkTWn/1
// https://stackoverflow.com/a/52619927/725866
export const postOfficeBoxRegex =
  /^\s*(.*((p|post)[-.\s]*(o|off|office)[-.\s]*(b|box|bin)[-.\s]*)|.*((p|post)[-.\s]*(o|off|office)[-.\s]*)|.*((p|post)[-.\s]*(b|box|bin)[-.\s]*)|(box|bin)[-.\s]*)(#|n|num|number)?\s*\d+/i;

export function isPreciseAddress(precision: AddressPrecision) {
  return (
    precision === 'Subaddress' ||
    precision === 'PointAddress' ||
    precision === 'StreetAddress' ||
    precision === 'POI'
  );
}

// https://developers.arcgis.com/rest/geocode/api-reference/geocoding-service-output.htm
export const addressPrecisions: AddressPrecision[] = [
  '',
  'Subaddress',
  'PointAddress',
  'StreetAddress',
  'StreetInt',
  'StreetAddressExt',
  'DistanceMarker',
  'StreetMidBlock',
  'StreetName',
  'Locality',
  'PostalLoc',
  'PostalExt',
  'Postal',
  'POI',
  'LatLong',
  'XY',
  'YX',
  'MGRS',
  'USNG'
];

export type AddressPrecision =
  | ''
  | 'Subaddress'
  | 'PointAddress'
  | 'StreetAddress'
  | 'StreetInt'
  | 'StreetAddressExt'
  | 'DistanceMarker'
  | 'StreetMidBlock'
  | 'StreetName'
  | 'Locality'
  | 'PostalLoc'
  | 'PostalExt'
  | 'Postal'
  | 'POI'
  | 'LatLong'
  | 'XY'
  | 'YX'
  | 'MGRS'
  | 'USNG';

export interface AddressFields {
  precision: AddressPrecision;
  street_address: string; // address 1
  sub_address: string; // address 2
  neighborhood: string;
  district: string;
  metro_area: string;
  city: string; // city
  region: string; // state
  region_abbr: string; // state abbrev
  subregion: string; // county
  postal_code: string; // zip
  postal_code_ext: string; // zip+4
  country: string;
  entry_x: number;
  entry_y: number;
  rooftop_x: number;
  rooftop_y: number;
}

export interface Address extends SharedResource, AddressFields {
  address_id: string;
  parent_id: string;
  is_primary_physical: boolean;
  is_primary_mailing: boolean;
  description: string;
}

export interface AddressSuggestion {
  text: string;
  magicKey: string;
  isCollection: boolean;
}

/**
 * https://developers.arcgis.com/rest/geocode/api-reference/geocoding-category-filtering.htm
 */
export type AddressCategory =
  | 'Primary Postal'
  | 'Region'
  | 'Subregion'
  | 'City';

export const addressCategoryToField: Record<
  AddressCategory,
  'city' | 'postal_code' | 'region_abbr' | 'subregion'
> = {
  City: 'city',
  'Primary Postal': 'postal_code',
  Region: 'region_abbr',
  Subregion: 'subregion'
};
