import type { CoreExpression, Identifier } from 'jsep';
import jsep from 'jsep';
import { Property } from '../model/entity-type';
import {
  ReportColumn,
  ReportParameter,
  aggregateFunctionNames,
  aggregateFunctionResultType,
  compositeProperties,
  identifierRegex
} from '../model/report';
import {
  ExpressionReturnType,
  printExpression,
  tryParseExpression
} from './common';

/*
in-use:
Identifier
Literal
CallExpression
BinaryExpression
UnaryExpression (only negation of literals)
*/

export const sqlDateParts = [
  'YEAR',
  'QUARTER',
  'MONTH',
  // 'DAYOFYEAR',
  'DAY',
  'WEEK'
  // 'HOUR',
  // 'MINUTE'
];

const functions: Record<
  string,
  {
    returnType: (
      ...args: { type: ExpressionReturnType | null; node: CoreExpression }[]
    ) => ExpressionReturnType;
    minArguments: number;
    arguments: (
      | {
          type: 'identifier';
          values: string[];
        }
      | { type: 'value'; valueType: ExpressionReturnType[]; values?: number[] }
    )[];
  }
> = {
  ABS: {
    returnType: num => num.type!,
    minArguments: 1,
    arguments: [{ type: 'value', valueType: ['int', 'decimal'] }]
  },
  DATEADD: {
    returnType: (_part, _num, date) => date.type!,
    minArguments: 3,
    arguments: [
      { type: 'identifier', values: sqlDateParts },
      { type: 'value', valueType: ['int', 'decimal'] },
      { type: 'value', valueType: ['date', 'datetime'] }
    ]
  },
  DATEDIFF: {
    returnType: () => 'int',
    minArguments: 3,
    arguments: [
      { type: 'identifier', values: sqlDateParts },
      { type: 'value', valueType: ['date', 'datetime'] },
      { type: 'value', valueType: ['date', 'datetime'] }
    ]
  },
  DATEPART: {
    returnType: () => 'int',
    minArguments: 2,
    arguments: [
      { type: 'identifier', values: sqlDateParts },
      { type: 'value', valueType: ['date', 'datetime'] }
    ]
  },
  DATETRUNC: {
    returnType: () => 'date',
    minArguments: 2,
    arguments: [
      { type: 'identifier', values: sqlDateParts },
      { type: 'value', valueType: ['date', 'datetime'] }
    ]
  },
  CEILING: {
    returnType: () => 'int',
    minArguments: 1,
    arguments: [{ type: 'value', valueType: ['int', 'decimal'] }]
  },
  EOMONTH: {
    returnType: () => 'date',
    minArguments: 1,
    arguments: [
      { type: 'value', valueType: ['date', 'datetime'] },
      { type: 'value', valueType: ['int', 'decimal'] }
    ]
  },
  FLOOR: {
    returnType: () => 'int',
    minArguments: 1,
    arguments: [{ type: 'value', valueType: ['int', 'decimal'] }]
  },
  NOW: {
    returnType: () => 'datetime',
    minArguments: 0,
    arguments: []
  },
  ROUND: {
    returnType: (_num, len) =>
      len?.node.type === 'Literal' && len.node.value === 0 ? 'int' : 'decimal',
    minArguments: 2,
    arguments: [
      { type: 'value', valueType: ['int', 'decimal'] },
      { type: 'value', valueType: ['int'], values: [0, 1, 2, 3] }
    ]
  },
  AGE: {
    returnType: () => 'int',
    minArguments: 2,
    arguments: [
      { type: 'value', valueType: ['date', 'datetime'] },
      { type: 'value', valueType: ['date', 'datetime'] }
    ]
  }
};

interface ReportExpressionValidationContext {
  ancestorNames: string[];
  parameters: ReportParameter[];
  columns: ReportColumn[];
  properties: Property[];
}

type ReportExpressionValidationResult =
  | { valid: true; type: ExpressionReturnType }
  | { valid: false; reason: string };

function validateReportExpression({
  node,
  context
}: {
  node: CoreExpression;
  context: ReportExpressionValidationContext;
}): ReportExpressionValidationResult {
  const expandExpressionIdentifier = (node: Identifier, expression: string) => {
    const name = node.name.toUpperCase();
    const parseResult = tryParseExpression(expression);
    if (!parseResult.valid) {
      return {
        valid: false,
        reason: `Referenced column "${name}" has an invalid expression. ${parseResult.reason}`
      } as ReportExpressionValidationResult;
    }
    Object.keys(node).forEach(key => delete node[key]);
    Object.assign(node, parseResult.node);
    const newContext = {
      ...context,
      ancestorNames: [...context.ancestorNames, name]
    };
    return validateReportExpression({ node, context: newContext });
  };

  switch (node.type) {
    case 'BinaryExpression': {
      const { left, right, operator } = node;
      if (['+', '-', '*', '/'].includes(operator)) {
        const leftResult = validateReportExpression({
          node: left as CoreExpression,
          context
        });
        if (leftResult.valid === false) {
          return leftResult;
        }
        if (leftResult.type !== 'int' && leftResult.type !== 'decimal') {
          return {
            valid: false,
            reason: `The expression "${printExpression(
              left as CoreExpression,
              []
            )}" cannot be used with the "${operator}" operator because it does not evaluate to a number.`
          };
        }
        const rightResult = validateReportExpression({
          node: right as CoreExpression,
          context
        });
        if (rightResult.valid === false) {
          return rightResult;
        }
        if (rightResult.type !== 'int' && rightResult.type !== 'decimal') {
          return {
            valid: false,
            reason: `The expression "${printExpression(
              left as CoreExpression,
              []
            )}" cannot be used with the "${operator}" operator because it does not evaluate to a number.`
          };
        }
        if (
          operator === '/' ||
          leftResult.type === 'decimal' ||
          rightResult.type === 'decimal'
        ) {
          return { valid: true, type: 'decimal' };
        } else {
          return { valid: true, type: 'int' };
        }
      }
      return { valid: false, reason: `Invalid expression "${operator}".` };
    }
    case 'CallExpression': {
      const callee = node.callee as CoreExpression;
      if (callee.type !== 'Identifier') {
        return { valid: false, reason: `Invalid expression.` };
      }
      const fn = functions[callee.name.toUpperCase()];
      if (!fn) {
        return { valid: false, reason: `Invalid expression "${callee.name}".` };
      }
      if (fn.minArguments > node.arguments.length) {
        return {
          valid: false,
          reason: `Too few arguments for "${callee.name}" function. Expected ${
            fn.minArguments === fn.arguments.length
              ? fn.minArguments
              : `${fn.minArguments} - ${fn.arguments.length}`
          }.`
        };
      }
      const argTypes: (ExpressionReturnType | null)[] = [];
      for (let i = 0; i < node.arguments.length; i++) {
        const arg = fn.arguments[i];
        const argNode = node.arguments[i] as CoreExpression;
        if (arg.type === 'identifier') {
          if (
            argNode.type !== 'Identifier' ||
            !arg.values.includes(argNode.name.toUpperCase())
          ) {
            return {
              valid: false,
              reason: `Invalid argument ${i + 1} for ${
                callee.name
              }. Expected ${arg.values.join(', ')}.`
            };
          }
          argTypes.push(null);
        } else if (arg.type === 'value') {
          const result = validateReportExpression({
            node: argNode,
            context
          });
          if (result.valid === false) {
            return result;
          }
          if (!arg.valueType.includes(result.type)) {
            return {
              valid: false,
              reason: `Invalid argument ${i + 1} for ${callee.name}. Expected ${
                arg.valueType[0]
              }.`
            };
          }
          if (
            arg.values &&
            (argNode.type !== 'Literal' ||
              !arg.values.includes(argNode.value as number))
          ) {
            return {
              valid: false,
              reason: `Invalid argument ${i + 1} for ${
                callee.name
              }. Expected ${arg.values.join(', ')}.`
            };
          }
          argTypes.push(result.type);
        } else {
          throw new Error(`Unexpected argument type "${(arg as any).type}".`);
        }
      }
      return {
        valid: true,
        type: fn.returnType(
          ...node.arguments.map((node, i) => ({
            node: node as CoreExpression,
            type: argTypes[i]
          }))
        )
      };
    }
    case 'Identifier': {
      const name = node.name.toUpperCase();
      if (context.ancestorNames.includes(name)) {
        return {
          valid: false,
          reason: `Reference to "${name}" creates a circular reference.`
        };
      }
      if (/^[A-Z]$/.test(name)) {
        if (context.ancestorNames.some(x => identifierRegex.test(x))) {
          return {
            valid: false,
            reason: `Column reference "${name}" is invalid. Columns cannot be referenced in a parameter formula..`
          };
        }
        const index = name.charCodeAt(0) - 'A'.charCodeAt(0);
        if (index >= context.columns.length) {
          return {
            valid: false,
            reason: `Invalid column reference "${name}". Column does not exist.`
          };
        }
        const column = context.columns[index];
        if (column.type === 'expression') {
          return expandExpressionIdentifier(node, column.expression);
        }
        const { type, property } = getColumnType(column, context.properties);
        if (type) {
          return { valid: true, type };
        }
        const descriptor = property
          ? `${
              column.type === 'subquery'
                ? aggregateFunctionNames[column.aggregate] + ' '
                : ''
            }"${property.label}"`
          : `Column ${name}`;
        return {
          valid: false,
          reason: `Invalid column reference "${name}". ${descriptor} is not eligible for use in formulas.`
        };
      }
      if (identifierRegex.test(name)) {
        const parameter = context.parameters.find(x => x.name === name);
        if (!parameter) {
          return {
            valid: false,
            reason: `Invalid parameter reference "${name}".`
          };
        }
        switch (parameter.type) {
          case 'expression':
            return expandExpressionIdentifier(node, parameter.expression);
          case 'literal-date':
          case 'select-date':
            return { valid: true, type: 'date' };
          case 'literal-number':
            return {
              valid: true,
              type: 'decimal'
            };
          case 'select-number':
            return {
              valid: true,
              type: parameter.values.every(x => Number.isInteger(x.value))
                ? 'int'
                : 'decimal'
            };
          default:
            throw new Error(
              `Unexpected parameter type "${(parameter as any).type}".`
            );
        }
      }
      return { valid: false, reason: `Invalid expression "${name}".` };
    }
    case 'UnaryExpression': {
      const { operator, argument } = node;
      if (operator !== '-' || argument.type !== 'Literal') {
        return { valid: false, reason: `Invalid expression "${operator}".` };
      }
      const result = validateReportExpression({
        node: argument as CoreExpression,
        context
      });
      if (result.valid === false) {
        return result;
      }
      if (result.type !== 'decimal' && result.type !== 'int') {
        return {
          valid: false,
          reason: `Cannot negate ${result.type} expression.`
        };
      }
      return result;
    }
    case 'Literal': {
      const { value, raw } = node;
      if (
        typeof value === 'number' &&
        isFinite(value) &&
        value >= -10000000 &&
        value <= 10000000
      ) {
        return {
          valid: true,
          type: Number.isInteger(value) ? 'int' : 'decimal'
        };
      }
      return { valid: false, reason: `Invalid expression "${raw}".` };
    }
    default:
      return { valid: false, reason: 'Invalid expression.' };
  }
}

export function reportColumnIndexToIdentifierName(index: number): string {
  return String.fromCharCode(index + 'A'.charCodeAt(0));
}

export function parseAndValidateReportExpression({
  name,
  expression,
  getDefinitionInfo
}: {
  name: string;
  expression: string;
  getDefinitionInfo: () => {
    parameters: ReportParameter[];
    columns: ReportColumn[];
    properties: Property[];
  };
}):
  | {
      valid: true;
      type: ExpressionReturnType;
      node: CoreExpression;
      originalNode: CoreExpression;
    }
  | { valid: false; reason: string } {
  const parseResult = tryParseExpression(expression);
  if (!parseResult.valid) {
    return parseResult;
  }
  const { node } = parseResult;
  const originalNode = structuredClone(node);
  const result = validateReportExpression({
    node,
    context: { ancestorNames: [name], ...getDefinitionInfo() }
  });
  if (!result.valid) {
    return result;
  }
  const permitted = ['int', 'decimal', 'date', 'datetime'];
  if (!permitted.includes(result.type)) {
    return {
      valid: false,
      reason: `Expression results of type ${
        result.type
      } are not supported. Expected: ${permitted.join(', ')}.`
    };
  }
  return { valid: true, node, originalNode, type: result.type };
}

export function tryParseAndTranslateReportExpressionColumnReferences(
  value: string,
  map: Record<number, number | null>
): CoreExpression | null {
  if (value === '') {
    return null;
  }
  let node: CoreExpression;
  try {
    node = jsep(value) as CoreExpression;
  } catch {
    return null;
  }
  if (tryTranslateReportExpressionColumnReferences(node, map)) {
    return node;
  }
  return null;
}

function tryTranslateReportExpressionColumnReferences(
  node: CoreExpression,
  map: Record<number, number | null>
): boolean {
  switch (node.type) {
    case 'BinaryExpression': {
      const { left, right } = node;
      return (
        tryTranslateReportExpressionColumnReferences(
          left as CoreExpression,
          map
        ) &&
        tryTranslateReportExpressionColumnReferences(
          right as CoreExpression,
          map
        )
      );
    }
    case 'CallExpression': {
      let translated = true;
      for (const arg of node.arguments) {
        translated &&= tryTranslateReportExpressionColumnReferences(
          arg as CoreExpression,
          map
        );
      }
      return translated;
    }
    case 'Identifier': {
      const deletedName = 'DELETED';
      if (/^[A-Z]$/.test(node.name.toUpperCase())) {
        const index = node.name.toUpperCase().charCodeAt(0) - 'A'.charCodeAt(0);
        const new_index = map[index];
        if (new_index === null) {
          node.name = deletedName;
        } else {
          node.name = String.fromCharCode(new_index + 'A'.charCodeAt(0));
        }
        return true;
      } else {
        return true;
      }
    }
    case 'UnaryExpression':
    case 'Literal':
      return true;
    default:
      return false;
  }
}

export function getColumnType(
  column: ReportColumn,
  properties: Property[]
): {
  type: ExpressionReturnType | null;
  property?: Property;
} {
  const getPropertyType: {
    (property: Property): {
      type: ExpressionReturnType | null;
      property: Property;
    };
  } = (property: Property) => {
    switch (property.type) {
      case 'int':
        return { type: 'int', property };
      case 'currency':
      case 'decimal':
        return { type: 'decimal', property };
      case 'date':
        return { type: 'datetime', property };
      case 'bit':
        return { type: 'boolean', property };
      case 'varchar100':
      case 'varcharmax':
        return { type: 'string', property };
      default:
        return { type: null, property };
    }
  };
  switch (column.type) {
    case 'standard': {
      const property = properties.find(
        p => p.property_id === column.property_id
      );
      if (!property) {
        throw new Error(
          `Unable to find standard property ${column.property_id}.`
        );
      }
      if (
        compositeProperties.includes(property.property_id) ||
        property.choice_type !== 'none'
      ) {
        return { type: null, property };
      }
      return getPropertyType(property);
    }
    case 'expression': {
      return { type: null };
    }
    case 'subquery': {
      if (column.aggregate === 'count') {
        return { type: 'int' };
      }
      const aggregateResultType = aggregateFunctionResultType[column.aggregate];
      switch (aggregateResultType) {
        case 'decimal':
          return { type: 'decimal' };
        case 'int':
          return { type: 'int' };
        case 'inherit-number':
        case 'inherit':
          const property = properties.find(
            p => p.property_id === column.subquery_property_id
          );
          if (!property) {
            throw new Error(
              `Unable to find subquery property ${column.subquery_property_id}.`
            );
          }
          return getPropertyType(property);
        default:
          throw new Error(
            `Unexpected aggregate result type "${aggregateResultType}" for subquery property ${column.subquery_property_id}.`
          );
      }
    }
    default:
      throw new Error(`Unexpected column type "${(column as any).type}".`);
  }
}
