export const loginMaxLength = 30;

export const loginRegex = /^[a-zA-Z\d](?:[a-zA-Z\d]|-(?=[a-zA-Z\d])){0,29}$/;

export const loginNoAnchors = new RegExp(
  loginRegex.source.substr(1, loginRegex.source.length - 2)
).source;
const markdownTerminators = /[\s\\`*^[\]!&<>{}$%@~\-+=:\/_\,\.\?();]/.source;

export const userOrganizationMentionRegex = new RegExp(
  `(^|${markdownTerminators})(@${loginNoAnchors}\/${loginNoAnchors})(?:$|${markdownTerminators})`,
  'g'
);

export const authOUserIdRegex = /^[a-f0-9]{24}$/;

export const passwordMinLength = 14;
export const passwordMaxLength = 70; // auth0 limit is 72
