export const minDate = Date.UTC(1900, 0, 1);
export const maxDate = Date.UTC(3000, 11, 31);

export function parseUserDate(value: string): Date | undefined {
  if (value.trim() === '') {
    return undefined;
  }

  const regex =
    /^\s*(\d{1,2})\s*[.,-\/]\s*(\d{1,2})(?:\s*[.,-\/]\s*(\d{2,4}))?\s*$/;
  const match = regex.exec(value);
  if (!match) {
    return undefined;
  }
  const [, month, day, year] = match;
  const date = new Date();
  date.setDate(1);
  date.setHours(0, 0, 0, 0);
  if (year) {
    let yearn = parseInt(year);
    if (yearn <= date.getFullYear() - 2000) {
      yearn += 2000;
    } else if (yearn < 100) {
      yearn += 1900;
    } else if (yearn < 1000) {
      yearn *= 10;
    }
    date.setFullYear(yearn);
  }
  if (month) {
    date.setMonth(parseInt(month) - 1);
  }
  if (day) {
    date.setDate(parseInt(day));
  }
  return date;
}

export function getAge(
  dob: Date,
  today = new Date()
): { years: number; months: number } {
  let years = today.getUTCFullYear() - dob.getUTCFullYear();
  let months = today.getUTCMonth() - dob.getUTCMonth();
  if (months < 0 || (months === 0 && today.getUTCDate() < dob.getUTCDate())) {
    years--;
  }
  if (months < 0) {
    months += 12;
  }
  return { years, months };
}

export function addDays(date: Date | number, days: number): Date {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const relativeDatePattern = /^(\d+) days? (ago|from now)$/;

export function isRelativeDate(value: string): boolean {
  return (
    value === 'Today' ||
    value === 'Yesterday' ||
    value === 'Tomorrow' ||
    relativeDatePattern.test(value)
  );
}

export function parseRelativeDate(
  today: number,
  value: string
): number | undefined {
  if (value === 'Today') {
    return today;
  }
  if (value === 'Yesterday') {
    return addDays(today, -1).getTime();
  }
  if (value === 'Tomorrow') {
    return addDays(today, 1).getTime();
  }
  const [, rawN, rawDir] = relativeDatePattern.exec(value) || [];
  const n = parseInt(rawN);
  if (!isFinite(n) || n < 1 || n > 365) {
    return undefined;
  }
  const dir = rawDir === 'ago' ? -1 : 1;
  return addDays(today, dir * n).getTime();
}

/*
export function parseRelativeDateOrDate(value: string): string {
  if (isRelativeDate(value)) {
    return value;
  }
  const localDate = parseUserDate(value);
  if (!localDate) {
    throw new Error(
      `Expected relative date or user date. Received "${value}".`
    );
  }
  return localDate.toISOString().substr(0, 10);
}
*/

export const userDateFormat = new Intl.DateTimeFormat('en-us', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
});

export const userUTCDateFormat = new Intl.DateTimeFormat('en-us', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  timeZone: 'UTC'
});

export const weekdayFormat = new Intl.DateTimeFormat('en-us', {
  weekday: 'long'
});
